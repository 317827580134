import React from 'react';
import Card from '../components/Card';
import SEO from '../components/common/seo';

const IndexPage = () => (
  <>
    <SEO title="Home" />
    <div style={{ marginBottom: `1.45rem` }} />
    <h1>Welcome to HackDay!</h1>
    <div>
      <Card
        body={
          <>
            <p>
              Three times a year we set aside time to work on small projects that can be completed
              in 3 days. These projects can be to explore different ideas, such as building a new
              tool, exploring some new ways to look at old data, new ideas to foster
              cross-collaboration, digital marketing hacks, sales materials, etc.
            </p>
            <p>
              The hack days are to foster creativity and innovation muscles by exploring something
              new, learning, and even failing. Go push boundaries and failing is okay. The final
              piece is that "done is better than perfect", we want to get that project to a "done"
              state in 72 hours. Afterward, every team presents what they did and what they found,
              built, or learned to a larger group.
            </p>
          </>
        }
      >
        <h2>What Are Hack Days?</h2>
      </Card>
      <Card
        body={
          <>
            <p>All Hack Days are 3 days long on Wed to Fri.</p>
            <ul>
              <li><b>Spring:</b> April 17th - 19th, 2024</li>
              <li><b>Late Summer:</b> September 11th - 13th, 2024</li>
            </ul>
          </>
        }
      >
        <h2>When Are Hack Days?</h2>
      </Card>
      <Card
        body={
          <p>Not participating but want to be invited to the demos each Hack Day?
            {' '}<a href="https://go.infoscoutinc.com/hackdayinvite">Sign up here.</a></p>
        }
      >
        <h3>Demo Invites</h3>
      </Card>
      <Card
        body={
          <p>
            Visit the <a href="/faq">Frequently Asked Questions Page</a>
          </p>
        }
      >
        <h2>More Questions?</h2>
      </Card>
    </div>
  </>
);

export default IndexPage;
